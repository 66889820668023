import React from 'react';
import DatePicker from 'react-datepicker';

import './App.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
    BrowserRouter,
    Route,
    Routes,
    Link
} from 'react-router-dom';

import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool
} from 'amazon-cognito-identity-js';

const pool = new CognitoUserPool({
    UserPoolId: 'ap-northeast-1_Bw7CUy7ms',
    ClientId: '7giqkfoqgsbb7otiqhk95nkogj',
});

function Copyright() {
    const hiddenLink = (<a href="https://kumikomiya.com/category/announcement"
                           className="text-dark text-decoration-none"
                           target="_blank"
                           rel="noreferrer">Kumikomiya</a>);

    return <footer className="text-center mt-4">&copy; 2022 {hiddenLink}</footer>;
}

function LinkToPlay(props) {
    if (props.enabled && props.roomId) {
        return <a className="btn btn-secondary stretched-link" href={"play.html?room_id=" + props.roomId}>Enter</a>;
    }
    else {
        return <button className="btn btn-outline-secondary" disabled>Locked</button>;
    }
}

function HouseCard(props) {
    return (
        <div className="card bg-light h-100">
            <h3 className="card-header text-center" style={{fontFamily: 'gooddog-new'}}>{props.title}</h3>
            <div className="row g-0">
                <div className="col-4">
                    <div className="card-body"></div>
                    <img className="card-img ms-1" src={props.img} alt={props.img} />
                    <div className="card-body"></div>
                </div>
                <div className="col-8">
                    <ul className="card-text ms-1 mt-3">{props.texts.map(dom => <li key={dom.toString()}>{dom}</li>)}</ul>
                    <div className="card-body text-end">
                        <LinkToPlay roomId={props.roomId} enabled={props.enabled} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Header(props) {
    function logOut() {
        const user = pool.getCurrentUser();
        if (user) {
            user.signOut();
        }
        props.updateUser(null);
        window.location.replace('./');
    }

    return (
        <React.Fragment>
            <nav className="navbar navbar-dark bg-primary p-0">
                <span className="navbar-brand ms-2 mb-1">
                    <img src="img/white240.png" alt="tonteki" title="tonteki" width="120" />
                </span>
                <button className="w-10 btn btn-light btn-sm m-1 ms-auto" onClick={logOut}>Log out</button>
            </nav>
            <div className="text-muted text-end me-1" style={{fontSize: '8px'}}>Your ID: {props.user.id}</div>
        </React.Fragment>
    );
}

function LobbyPage(props) {
    const isMember = (props.user && 'token' in props.user);
    // const isStrong = (props.perf && props.perf.Level >= 10);

    function Links() {
        if (isMember) {
            return (
                <React.Fragment>
                    <div className="m-4"><Link to="performance">Performance</Link></div>
                    <div className="m-4"><Link to="history">Hand history</Link></div>
                </React.Fragment>
            );
        }
        else {
            return <div className="m-4"><Link to="signup">Member registration</Link></div>;
        }
    }

    return (
        <React.Fragment>
            <Header user={props.user} updateUser={props.updateUser} />
            <h1 className="text-center mt-3 mb-3">The Game Is Ready</h1>
            <div className="text-center mb-3">Make your chips 25,000 to win the game!</div>
            <div className="container">
                <div className="row justify-content-around">
                    <div className="col-lg-3 m-2">
                        <HouseCard
                            title="Straw House"
                            img="img/straw.png"
                            roomId="000fa34a-cea0-4077-929a-8c411f17fad4"
                            texts={["Free", "6-Max"]}
                            enabled={true}
                        />
                    </div>
                    <div className="col-lg-3 m-2">
                        <HouseCard
                            title="Wood House"
                            img="img/wood.png"
                            roomId="ca3f25e8-66af-4e78-917e-7255b73280f6"
                            texts={["Free", "6-Max", "Members Only", <b>Under Construction</b>]}
                            // enabled={isMember}
                            enabled={false}
                        />
                    </div>
                    <div className="col-lg-3 m-2">
                        <HouseCard
                            title="Brick House"
                            img="img/brick.png"
                            roomId="3d5d7f0d-8fc2-448a-93ff-234d6f27a595"
                            texts={["Free", "9-Max", "Level 10+ Needed", <b>Under Construction</b>]}
                            // enabled={isStrong}
                            enabled={false}
                        />
                    </div>
                </div>
            </div>
            <div className="text-center"><Links /></div>
            <Copyright />
        </React.Fragment>
    );
}

function SignUpForms(props) {
    const [checked, setChecked] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');

    function check(e) {
        setChecked(e.target.checked);
    }

    function changeEmail(e) {
        setEmail(e.target.value);
    }

    function changePassword1(e) {
        setPassword1(e.target.value);
    }

    function changePassword2(e) {
        setPassword2(e.target.value);
    }

    function valid() {
        return (email && password1 && checked && password1 === password2);
    }

    function submit() {
        props.submit(email, password1);
    }

    return (
        <React.Fragment>
            <div className="container col-lg-6">
                <div className="row">
                    <div className="col">
                        <input
                            className="form-check-input ms-4"
                            type="checkbox"
                            checked={checked}
                            onChange={check}
                        />
                        <label className="form-check-label ms-2 mb-4" htmlFor="flexCheckChecked"><b>I understand the above.</b></label>
                    </div>
                </div>
            </div>
            <div className="container col-sm-4">
                <div className="row">
                    <div className="col">
                        <input
                            className="form-control mb-1"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={changeEmail}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            className="form-control mb-1"
                            type="password"
                            placeholder="Password"
                            value={password1}
                            onChange={changePassword1}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            className="form-control mb-1"
                            type="password"
                            placeholder="Password (for confirmation)"
                            value={password2}
                            onChange={changePassword2}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {valid() ? <button className="w-100 btn btn-danger" onClick={submit}>Sign up</button>
                                 : <button className="w-100 btn btn-outline-danger" disabled>Sign up</button>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function VerifyForms(props) {
    const [code, setCode] = React.useState('');

    function changeCode(e) {
        const text = e.target.value;
        if (/^[0-9]+$/.test(text)) {
            setCode(text);
        }
    }

    function submit() {
        props.submit(code);
    }

    return (
        <div className="container col-lg-6">
            <div className="row text-center m-3">
                <div className="col">
                    <b>Enter the verification code that was just sent to your email address.</b>
                    <button className="btn btn-link btn-sm justify-content-center" onClick={props.resend}>Resend code</button>
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                <div className="col-4">
                    <input
                        className="row form-control form-control-lg mt-2"
                        type="text"
                        maxLength="6"
                        value={code}
                        onChange={changeCode}
                        autoFocus
                    />
                </div>
                <div className="col-2">
                    {(code.length === 6) ? <button className="row btn btn-dark mt-1" onClick={submit}>Verify</button>
                                         : <button className="row btn btn-outline-dark mt-1" disabled>Verify</button>}
                </div>
            </div>
        </div>
    );
}

function SignUpPage() {
    const [state, setState] = React.useState(0);
    const [user, setUser] = React.useState(null);

    function signUp(email, password) {
        pool.signUp(email, password, [], null, function(err, result) {
            if (!err) {
                setUser(result.user);
                setState(1);
            }
            else if (!err.message) {
                alert(JSON.stringify(err));
            }
            else if (err.message.includes("Value at 'password' failed to satisfy constraint")) {
                alert('Password is too short; must be at least 6 characters.');
            }
            else {
                alert(err.message);
            }
        });
    }

    function confirm(code) {
        if (user) {
            user.confirmRegistration(code, true, function(err, result) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                else {
                    sessionStorage.removeItem('user');
                    setState(2);
                }
            });
        }
    }

    function resend() {
        if (user) {
            user.resendConfirmationCode(function(err, result) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                else {
                    alert('A new code has been sent to your email address.');
                }
            });
        }
    }

    function BeforeSignUp() {
        return (
            <React.Fragment>
                <div className="container col-lg-6">
                    <div className="row">
                        <div className="col">
                            <h1 className="text-center m-3">NOTICE</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ul>
                                <li className="m-3">Your hands will become visible to your opponents after the game, whether you showed it or not in the game.</li>
                                <li className="m-3">Any information about the games may be disclosed to third parties as non-personally identifiable statistics.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {(state === 0) ? <SignUpForms submit={signUp} /> : <VerifyForms submit={confirm} resend={resend} />}
                <Copyright />
            </React.Fragment>
        );
    }

    function AfterSignUp() {
        return (
            <React.Fragment>
                <div className="container col-lg-4">
                    <div className="row text-center m-3">
                        <div className="col"><h1>Welcome</h1></div>
                    </div>
                    <div className="row m-3">
                        <div className="col">Your email address has been successfully registered. Enjoy!</div>
                    </div>
                    <div className="row text-center m-3">
                        <div className="col"><Link to="/">Back to lobby</Link></div>
                    </div>
                </div>
                <Copyright />
            </React.Fragment>
        );
    }

    return (state === 2) ? <AfterSignUp /> : <BeforeSignUp />;
}

const URL_HTTP = 'https://byfknkd9el.execute-api.ap-northeast-1.amazonaws.com';

function request(method, url, receive, user) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                const res = xhr.response;
                receive(res ? JSON.parse(res) : null);
            }
            else {
                console.log(xhr);
            }
        }
    };
    if (user) {
        if (user.token) {
            xhr.setRequestHeader('Authorization', `Bearer ${user.token}`);                
        }
        else if (user.guest) {
            xhr.setRequestHeader('Authorization', `Basic ${user.guest}`);                
        }
    }
    xhr.send();
}

function LogInPage(props) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const changeEmail = e => setEmail(e.target.value);
    const changePassword = e => setPassword(e.target.value);

    function submit() {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: pool,
        });

        cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');

        const data = {
            Username: email,
            Password: password,
        };

        cognitoUser.authenticateUser(new AuthenticationDetails(data), {
            onSuccess: function(result) {
                // User authentication was successful
                const idToken = result.getIdToken();
                props.updateUser({
                    id: idToken.payload.sub,
                    token: idToken.jwtToken
                });
            },
            onFailure: function(err) {
                // User authentication was not successful
                alert(err.message || JSON.stringify(err));
            },
        });
    }

    function guest() {
        request('POST', URL_HTTP + '/guest', function(obj) {
            const id = obj.UserId;
            const password = obj.Password;
            const str = `${id}:${password}`;
            const guest = btoa(str);
            props.updateUser({id: id, guest: guest});
        });
    }

    return (
        <React.Fragment>
            <h1 className="text-center mt-3">
                <img src="img/tonteki480.png" alt="tonteki" title="tonteki" width="240" />
            </h1>
            <div className="text-center mb-3" style={{fontFamily: 'gooddog-new'}}>Texas Holdem for Everyone</div>
            <div className="container text-center col-sm-4">
                <div className="row">
                    <div className="col">
                        <input
                            className="form-control mb-1"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={changeEmail}
                            autoFocus
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            className="form-control mb-1"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={changePassword}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="w-100 btn btn-primary" onClick={submit} disabled={!(email && password)}>Log in</button>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-link fw-bold" onClick={guest}>Guest mode</button>
            </div>
            <div className="text-center mt-3">
                <Link to="/signup" className="text-secondary">Member registration</Link>
            </div>
            <Copyright />
        </React.Fragment>
    );
}

function MembersOnlyPage(props) {
    return (
        <React.Fragment>
            <Header user={props.user} updateUser={props.updateUser} />
            <h1 className="text-center">Members Only</h1>
            <Copyright />
        </React.Fragment>
    );
}

function PerformancePage(props) {
    function makeMission(a, b) {
        if (a === undefined || b === undefined) {
            return 'Nothing. You are already strong enough!';
        }
        else if (b === 1) {
            return 'Win a game.';
        }
        else if (a === b) {
            return `Win ${b} games in a row.`;
        }
        else {
            return `Win a game, and satisfy ${b} wins of the recent ${a} games.`;
        }
    }

    function convert(chain) {
        return chain.split('').map(c => (c === '1') ? '⭐' : '➖').join('');
    }

    function makeStars(chain) {
        if (chain) {
            const n = chain.length;
            return (
                <React.Fragment>
                    <h6 className="card-text mt-3">{(n > 1) ? `Your recent ${n} games` : 'Your latest game'}</h6>
                    <div className="card-text">{convert(chain)}</div>
                </React.Fragment>
            );
        }
        else {
            return <React.Fragment></React.Fragment>;
        }
    }

    function card(perf) {
        if (perf) {
            return (
                <div className="card m-2 text-dark bg-light">
                    <div className="card-body">
                        <h6 className="card-title">Requirements for the next level</h6>
                        <div className="card-text small">{makeMission(perf.A, perf.B)}</div>
                        {makeStars(perf.Chain)}
                    </div>
                </div>
            );
        }
        else {
            return <React.Fragment></React.Fragment>;
        }
    }

    if (props.user && 'token' in props.user) {
        const perf = props.perf;
        return (
            <React.Fragment>
                <Header user={props.user} updateUser={props.updateUser} />
                <div className="text-end me-2"><Link to="/">Back to lobby</Link></div>
                <div className="container col-lg-4">
                    <div className="row align-items-center">
                        <div className="col ms-3" style={{fontFamily: "geom-graphic"}}>Level</div>
                        <div className="col" style={{fontFamily: "geom-graphic", fontSize: 128}}>{perf ? perf.Level : ''}</div>
                    </div>
                    <div className="row">
                        <div className="col">{card(perf)}</div>
                    </div>
                </div>
                <Copyright />
            </React.Fragment>
        );
    }
    else {
        return <MembersOnlyPage user={props.user} updateUser={props.updateUser} />;
    }
}

function HistoryPage(props) {
    const today = new Date();
    const [date, setDate] = React.useState(today);
    const [items, setItems] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);
    const [filtered, setFiltered] = React.useState(false);

    function loadAsync(date) {
        const key = date.toISOString().substr(0, 10);
        const url = `${URL_HTTP}/game/list?date=${key}`;
        request('GET', url, setItems, props.user);
    }

    if (!loaded && 'token' in props.user) {
        setLoaded(true);
        loadAsync(today);
    }

    function Card(props) {
        const rank = props.text[0];
        const suit = props.text[1];
        switch (suit) {
        case 's':
            return <span className="text-dark">{rank + '♠'}</span>;
        case 'h':
            return <span className="text-danger">{rank + '♥'}</span>;
        case 'd':
            return <span className="text-danger">{rank + '♢'}</span>;
        case 'c':
            return <span className="text-dark">{rank + '♧'}</span>;
        default:
            return <span></span>;
        }
    }

    function Cards(props) {
        const cards = props.text.split(' ');
        return (
            <code className="h6">
                <Card text={cards[0]} /> <Card text={cards[1]} />
            </code>
        );
    }

    function toString(chip) {
        if (typeof chip !== 'number') {
            return '';
        }
        else if (chip < 0) {
            return chip.toLocaleString();
        }
        else if (chip > 0) {
            return '+' + chip.toLocaleString();
        }
        else {
            return '0';
        }
    }

    function isChecked(gameId) {
        const key = 'game:' + gameId;
        const text = localStorage.getItem(key);
        const item = JSON.parse(text ?? '{}');
        return 'checked' in item && item.checked;
    }

    function Row(props) {
        const item = props.item;
        const [checked, setChecked] = React.useState(isChecked(item.GameId));

        function onChange(e) {
            const key = 'game:' + e.target.id;
            const text = localStorage.getItem(key);
            const item = JSON.parse(text ?? '{}');
            item.checked = e.target.checked;
            localStorage.setItem(key, JSON.stringify(item));
            setChecked(e.target.checked);
        }

        function jump()
        {
            // a タグだと sessionStorage が引き継がれないため、window.open を使う
            sessionStorage.setItem('hash', item.Hash);
            window.open(`replay.html?game_id=${item.GameId}&hash=${item.Hash}`);
        }

        return (
            <tr>
                <td className="text-center"><input className="form-check-input" id={item.GameId} type="checkbox" checked={checked} onChange={onChange} /></td>
                <td className="text-center small">{item.StartedAt.substr(11, 8)}</td>
                <td className="text-center"><Cards text={item.Hole} /></td>
                <td className="text-end small">{toString(item.ChipEarned)}</td>
                <td className="text-center"><button className="btn btn-dark btn-sm" onClick={jump}>▶</button></td>
            </tr>
        );
    }

    function makeRows(filtered) {
        if (items === null) {
            return (
                <tr>
                    <td className="text-center" colSpan="5">
                        <div className="spinner-border spinner-border-sm text-secondary me-1" role="status" />Loading...
                    </td>
                </tr>
            );
        }
        else if (items.length === 0) {
            return <tr><td className="text-center" colSpan="5">No games on this day</td></tr>;
        }
        else {
            const predicate = filtered ? item => isChecked(item.GameId) : () => true;
            return items.filter(predicate).map(item => <Row key={item.GameId} item={item} />);
        }
    }

    if (props.user && 'token' in props.user) {
        return (
            <React.Fragment>
                <Header user={props.user} updateUser={props.updateUser} />
                <div className="text-end me-2"><Link to="/">Back to lobby</Link></div>
                <h2 className="m-2">History</h2>
                <div className="alert alert-warning" role="alert">
                    This view will soon be a monthly subscription service.
                </div>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <DatePicker
                            className="m-2 text-center"
                            dateFormat="yyyy-MM-dd"
                            selected={date}
                            maxDate={today}
                            onChange={date => { setDate(date); loadAsync(date); }}
                        />
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" value={filtered} onChange={e => setFiltered(e.target.checked)} />
                            <label className="form-check-label">Filter</label>
                        </div>
                    </div>
                </div>
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th className="text-center" scope="col"></th>
                            <th className="text-center" scope="col">UTC</th>
                            <th className="text-center" scope="col">Hand</th>
                            <th className="text-center" scope="col">Gain</th>
                            <th className="text-center" scope="col">Replay</th>
                        </tr>
                    </thead>
                    <tbody>
                        {makeRows(filtered)}
                    </tbody>
                </table>
                <Copyright />
            </React.Fragment>
        );
    }
    else {
        return <MembersOnlyPage user={props.user} updateUser={props.updateUser} />;
    }
}

function refreshAsync(updateUser) {
    const user = pool.getCurrentUser();
    if (user) {
        let i = 0;
        // retry once if getSession returned undefined because of a bug
        const handle = setInterval(() => {
            ++i;
            const idToken = user.getSession((err, session) => err ? null : session.getIdToken());
            if (idToken === undefined && i < 2) {
                console.log('undefined');
            }
            else {
                clearInterval(handle);
            }
            if (idToken) {
                console.log('refreshed');
                updateUser({
                    id: idToken.payload.sub,
                    token: idToken.jwtToken
                });
            }
        }, 500);
    }
}

function loadUser() {
    const text = sessionStorage.getItem('user');
    return JSON.parse(text);
}

function MaintenancePage() {
    return (
        <React.Fragment>
            <h1 className="text-center">Under Maintenance</h1>
            <Copyright />
        </React.Fragment>
    );
}

function App() {
    const [state, setState] = React.useState(0);
    const [user, setUser] = React.useState(loadUser());
    const [perf, setPerf] = React.useState(null);

    function updateUser(item) {
        if (item) {
            sessionStorage.setItem('user', JSON.stringify(item));
        }
        else {
            sessionStorage.removeItem('user');
        }
        setUser(item);
        setState(2);
    }

    function loadAsync() {
        const url = `${URL_HTTP}/performance`;
        request('GET', url, setPerf, user);
    }

    switch (state) {
    case 0:
        setState(1);
        refreshAsync(updateUser);
        break;
    case 2:
        if ('token' in user) {
            setState(3);
            loadAsync();
        }
        break;
    default:
        break;
    }

    function loginOr(element) {
        return user ? element : <LogInPage updateUser={updateUser} />;
    }

    const maintenance = false;
    if (maintenance) {
        return <MaintenancePage />;
    }
    else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/history" element={loginOr(<HistoryPage user={user} updateUser={updateUser} />)} />
                    <Route path="/performance" element={loginOr(<PerformancePage user={user} updateUser={updateUser} perf={perf} />)} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/" element={loginOr(<LobbyPage user={user} updateUser={updateUser} perf={perf} />)} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
